import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Card from "react-bootstrap/Card";
import { graphql, Link } from "gatsby";

export default function Blog({ data }) {
  const { posts } = data.blog;

  return (
    <Layout>
      <Seo
        title="Experts in building digital solutions to help business succeed"
        description="Based in Delaware, we specialized in Web Design and Development, Mobile App Development, E-commerce web design, Search Engine Optimization, Branding, & UI/UX."
        keyword="About Us"
      />


      <Container fluid className="text-center">
        <br />
        <br />
        <br />
        <br />
        <h1 className="text-white">Our Blogs</h1>
        </Container>
        <div className="custom-shape-divider-bottom-1646157140">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
    </svg>
</div>
        <Container fluid className="bg-white mt-0 pt-0">
        <section id="cd-timeline" className="cd-container">
              {posts.map((post) => (
                <article key={post.id}>
                  <br />
                <div className="cd-timeline-block">
                  <div className="cd-timeline-img cd-picture"></div>
                  <div className="cd-timeline-content">
                      <Link className="text-decoration-none" to={post.fields.slug}>
                        <h2 className="fs-3 text-info">
                          {post.frontmatter.title}
                        </h2>
                        <p className="fs-5">{post.excerpt}</p>
                        <div className="timeline-content-info">
                          <span className="timeline-content-info-title">
                            <i>
                              <small className="text-muted">
                                Written by&nbsp;{post.frontmatter.author}
                              </small>
                            </i>
                          </span>
                          {/* <span className="timeline-content-info-date">
                            <i className="fa fa-calendar-o" aria-hidden="true"></i>
                            {post.frontmatter.date}
                          </span> */}
                        </div>
                      </Link>
                   </div>{/* cd-timeline-content */}
                </div>{/* cd-timeline-block */}
              </article>
              ))}
              <br /><br />
        </section>
        </Container>
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery {
    blog: allMarkdownRemark {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date(fromNow: true)
          title
          author
        }
        excerpt(pruneLength: 260)
        id
      }
    }
  }
`;
